import { ConnectorUpdate } from '@web3-react/types'
import { AbstractConnector } from '@web3-react/abstract-connector'
import invariant from 'tiny-invariant'
import { ethers } from 'ethers'

interface NetworkConnectorArguments {
  urls: { [chainId: number]: string }
  defaultChainId?: number
}

export class NetworkConnector extends AbstractConnector {
  private readonly providers: {
    [chainId: number]:
      | ethers.providers.StaticJsonRpcProvider
      | ethers.providers.InfuraProvider
  }
  private currentChainId: number

  constructor({ urls, defaultChainId }: NetworkConnectorArguments) {
    invariant(
      defaultChainId || Object.keys(urls).length === 1,
      'defaultChainId is a required argument with >1 url'
    )
    super({
      supportedChainIds: Object.keys(urls).map((k): number => Number(k)),
    })

    this.currentChainId = defaultChainId || Number(Object.keys(urls)[0])
    this.providers = Object.keys(urls).reduce<{
      [chainId: number]:
        | ethers.providers.StaticJsonRpcProvider
        | ethers.providers.InfuraProvider
    }>((accumulator, chainId) => {
      const _chainId = Number(chainId)
      const _url = urls[_chainId]
      if (_url.match('infura')) {
        accumulator[_chainId] = new ethers.providers.InfuraProvider(
          // Network name
          _url.match('rinkeby') ? 'rinkeby' : 'homestead',
          // API Key
          _url.split('/').pop()
        )
      } else {
        accumulator[_chainId] = new ethers.providers.StaticJsonRpcProvider(
          _url,
          _chainId
        )
      }
      accumulator[_chainId].pollingInterval = 12000
      return accumulator
    }, {})
  }

  public async activate(): Promise<ConnectorUpdate> {
    return {
      provider: this.providers[this.currentChainId],
      chainId: this.currentChainId,
      account: null,
    }
  }

  public async getProvider(): Promise<ethers.providers.StaticJsonRpcProvider> {
    return this.providers[this.currentChainId]
  }

  public async getChainId(): Promise<number> {
    return this.currentChainId
  }

  public async getAccount(): Promise<null> {
    return null
  }

  public deactivate() {
    return
  }

  public changeChainId(chainId: number) {
    invariant(
      Object.keys(this.providers).includes(chainId.toString()),
      `No url found for chainId ${chainId}`
    )
    this.currentChainId = chainId
    this.emitUpdate({ provider: this.providers[this.currentChainId], chainId })
  }
}
