import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { SimpleERC20TokenFactory } from "../abis/SimpleERC20TokenFactory";

export const useTransfers = (provider: string, token: string, from: string, to: string): string => {
  const [totalBurned, setTotalBurned] = useState<string | undefined>();

  const { library } = useWeb3React<ethers.providers.Web3Provider>(provider);
  if (!library) {
    throw new Error("Library not configured");
  }

  useEffect(() => {
    const contract = SimpleERC20TokenFactory.connect(token, library);
    const fetchOmiTransfers = async () => {
      return contract.queryFilter(contract.filters.Transfer(from, to));
    }

    fetchOmiTransfers().then((events) => {
      let totalBurned = new BigNumber("0");
      for (const {
        args: { value },
      } of events) {
        totalBurned = totalBurned.plus(
          ethers.utils.formatEther(value.toString()).toString()
        );
      }
      setTotalBurned(totalBurned.toString());
    });
  }, [library, token, from, to]);

  return totalBurned || "0";
};
