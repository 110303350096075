import { NetworkConnector } from "./networkConnector";
import { baseRpcUrl } from "./chainData";

export const ethereumConnector = new NetworkConnector({
  urls: {
    1: "https://mainnet.infura.io/v3/dbbf9430c4a246dd921edf1427e9209b",
  },
  defaultChainId: 1,
});

export const baseConnector = new NetworkConnector({
  urls: {
    1: baseRpcUrl,
  },
  defaultChainId: 1,
});
