import { Box, Grid } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useTransfers } from "../hooks/useTransfers";
import { addresses, baseExplorerUrl, ethereumExplorerUrl, gochainExplorerUrl,} from "../utils/chainData";
import { BalanceCard, BalanceName } from "./balanceCard";
import { useBaseBalance } from "../hooks/useBaseBalance";

export const Balances = () => {
  const [loading, setLoading] = useState(true);

  const gochainBurnTotal = "26381256826.7306980290600036";

  const ethereumProvider = useWeb3React<ethers.providers.Web3Provider>("ethereum");
  if (!ethereumProvider.library) {
    throw new Error("Ethereum library not configured");
  }

  const omiOnBase = addresses.omiToken.base
  const baseProvider = useWeb3React<ethers.providers.Web3Provider>("base");
  if (!baseProvider.library) {
    throw new Error("Base library not configured");
  }
  const totalEthereumBurnedByVeve = useTransfers("ethereum", addresses.omiToken.ethereum, addresses.wallets.veveBurn, ethers.constants.AddressZero);
  const totalEthereumBurnedByStackR = useTransfers("ethereum", addresses.omiToken.ethereum, addresses.wallets.stackrBurn, ethers.constants.AddressZero);
  const totalBaseBurnedInL2 = useBaseBalance("base", addresses.wallets.stackrBurn);

  useEffect(() => {
    if (
      totalEthereumBurnedByVeve &&
      totalEthereumBurnedByStackR &&
      totalBaseBurnedInL2
    ) {
      setLoading(false);
    }
  }, [
    totalEthereumBurnedByVeve,
    totalEthereumBurnedByStackR,
    totalBaseBurnedInL2
  ]);

  return (
    <Grid container justify="center">
      <Grid item sm={12} md={3}>
        <BalanceCard
          name="StackR Burns *"
          balances={[
            {
              name: BalanceName.BASE_L2,
              balance: loading ? "..." : totalBaseBurnedInL2,
              url: `${baseExplorerUrl}/token/${omiOnBase}?a=${addresses.wallets.stackrBurn.toLowerCase()}`,
            },
            {
              name: BalanceName.ETH_L1,
              balance: loading ? "..." : totalEthereumBurnedByStackR,
              url: `${ethereumExplorerUrl}${addresses.wallets.stackrBurn.toLowerCase()}`,
            },
            {
              name: BalanceName.TOTAL,
              balance: loading ? "..." : new BigNumber(totalEthereumBurnedByStackR).plus(totalBaseBurnedInL2).toString(),
            },
          ]}
        />
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={1}
        marginLeft={1}
        marginRight={1}
      >
      </Box>
      <Grid item sm={12} md={3}>
        <BalanceCard
          name="VeVe Burns"
          balances={[
            {
              name: BalanceName.GO,
              balance: loading ? "..." : gochainBurnTotal,
              url: `${gochainExplorerUrl}${addresses.wallets.veveBurn.toLowerCase()}`,
            },
            {
              name: BalanceName.ETH,
              balance: loading ? "..." : totalEthereumBurnedByVeve,
              url: `${ethereumExplorerUrl}${addresses.wallets.veveBurn.toLowerCase()}`,
            },
            {
              name: BalanceName.TOTAL,
              balance: loading
                ? "..."
                : new BigNumber(gochainBurnTotal)
                .plus(totalEthereumBurnedByVeve ?? "0")
                .toString(),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
