import {
  AppBar,
  Box,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#424242",
  },
  toolBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const SubHeader = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar className={classes.toolBar}>
        <Box
          width="100%"
          maxWidth="880px"
          height="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            height="100%"
            width="100%"
            display="flex"
            justifyContent="align-start"
          >
            <Typography variant="body1">OMI Token Tracker</Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
