import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      margin: 5,
    },
  },
  headerText: {
    color: "#fff",
  },
  balanceText: {
    fontSize: "22px",
    fontWeight: 300,
    textDecorationLine: "none",
  },
  footerText: {
    fontSize: "14px",
    color: "#5ecced",
    textDecoration: "none",
  },
}));

const getRenderedBalance = (balance: string) => {
  if (balance === "...") return balance;
  return ethers.utils.commify(new BigNumber(balance).toFixed(0));
};

export enum BalanceName {
  ETH = "ETH L1",
  BASE_L2 = "BASE L2",
  ETH_L1 = "ETH L1",
  GO = "Gochain",
  TOTAL = "Total",
}

interface Props {
  name: string;
  balances: {
    name: BalanceName;
    balance: string;
    url?: string;
  }[];
}

export const BalanceCard = ({ name, balances }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box paddingTop={2} paddingBottom={2}>
          <Typography className={classes.headerText}>{name}</Typography>
        </Box>
      </Box>
      <Card>
        <CardContent>
          {balances.map(({ name, balance, url }) => {
            const renderedBalance = getRenderedBalance(balance);
            return (
              <Box
                key={name}
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop={5}
                paddingBottom={5}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginRight={1}
                  >
                    <Typography className={classes.balanceText}>
                      {`${name}:`}
                    </Typography>
                    {name === BalanceName.TOTAL && (
                      <img
                        src="/images/omi-red.png"
                        alt="OMI logo icon"
                        width="20px"
                        height="26px"
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </Box>
                  <Box>
                    { renderedBalance === "..." ? (
                      <Typography className={ classes.balanceText }>{ renderedBalance }</Typography>
                    ) : (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={url}
                        className={classes.balanceText}
                      >
                        {renderedBalance}
                      </a>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </CardContent>
      </Card>
    </Box>
  );
};
