import { AppBar, Box, Grid, makeStyles, Toolbar } from "@material-ui/core";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#2c2c2c",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const Header = () => {
  const classes = useStyles();

  const { width } = useWindowDimensions();

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar className={classes.toolBar}>
        <Grid item xs={12} sm={2}>
          <Box
            marginLeft={1}
            height="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              src="/images/veve-logo.png"
              alt="Veve logo"
              width="120px"
              height="35px"
            />
          </Box>
        </Grid>
        {width > 1000 && (
          <Grid item sm={10}>
            <Box
              height="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <a
                style={{
                  color: "#5ecced",
                  fontWeight: 300,
                  textDecoration: "none",
                }}
                target="_blank"
                rel="noreferrer"
                href={"https://omi.veve.me"}
              >
                WALLET LOGIN
              </a>
            </Box>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};
