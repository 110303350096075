import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { addresses } from "../utils/chainData";
import { SimpleERC20TokenFactory } from "../abis/SimpleERC20TokenFactory";
import {useWeb3React} from "@web3-react/core";

export const useBaseBalance = (provider: string, walletAddress: string): string => {
    const [balance, setBalance] = useState<string>("0");
    const { library } = useWeb3React<ethers.providers.Web3Provider>(provider);

    useEffect(() => {
        if (!library) {
            throw new Error("Library not configured");
        }
        const fetchBalance = async () => {
            const contract = SimpleERC20TokenFactory.connect(addresses.omiToken.base, library);
            const balanceBigNumber = await contract.balanceOf(walletAddress);
            const formattedBalance = ethers.utils.formatEther(balanceBigNumber);
            setBalance(formattedBalance);
        };

        fetchBalance();
    }, [library, walletAddress]);

    return balance;
};