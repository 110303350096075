export const addresses = {
  omiToken: {
    ethereum: "0xeD35af169aF46a02eE13b9d79Eb57d6D68C1749e",
    base: "0x3792DBDD07e87413247DF995e692806aa13D3299",
  },
  wallets: {
    veveBurn: "0xbBDA162f1E3EC2D4D9D99cafd0c14B03EC4E78d3",
    stackrBurn: "0x821c1ed723C3148eB74540B1201Ea3369c910c17",
  },
};

export const gochainExplorerUrl = "https://explorer.gochain.io/addr/";

export const ethereumExplorerUrl = "https://etherscan.io/address/";

export const baseExplorerUrl = "https://basescan.org/";

export const baseRpcUrl = "https://base-rpc.publicnode.com";
